import { COLORS } from "theme/colors";

import { SvgIcon } from "./types";

const SvgIDragHandle: React.FC<SvgIcon> = (props) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={COLORS.IRIDIUM_WHITE}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7167 8.06669C15.7167 8.97796 16.4554 9.71669 17.3667 9.71669C18.2779 9.71669 19.0167 8.97796 19.0167 8.06669C19.0167 7.15542 18.2779 6.41669 17.3667 6.41669C16.4554 6.41669 15.7167 7.15542 15.7167 8.06669ZM15.7167 13.9334C15.7167 14.8446 16.4554 15.5834 17.3667 15.5834C18.2779 15.5834 19.0167 14.8446 19.0167 13.9334C19.0167 13.0221 18.2779 12.2834 17.3667 12.2834C16.4554 12.2834 15.7167 13.0221 15.7167 13.9334ZM11.5 15.5834C10.5887 15.5834 9.85 14.8446 9.85 13.9334C9.85 13.0221 10.5887 12.2834 11.5 12.2834C12.4113 12.2834 13.15 13.0221 13.15 13.9334C13.15 14.8446 12.4113 15.5834 11.5 15.5834ZM9.85 8.06669C9.85 8.97796 10.5887 9.71669 11.5 9.71669C12.4113 9.71669 13.15 8.97796 13.15 8.06669C13.15 7.15542 12.4113 6.41669 11.5 6.41669C10.5887 6.41669 9.85 7.15542 9.85 8.06669ZM5.63333 15.5834C4.72209 15.5834 3.98333 14.8446 3.98333 13.9334C3.98333 13.0221 4.72209 12.2834 5.63333 12.2834C6.54457 12.2834 7.28333 13.0221 7.28333 13.9334C7.28333 14.8446 6.54457 15.5834 5.63333 15.5834ZM3.98333 8.06669C3.98333 8.97796 4.72209 9.71669 5.63333 9.71669C6.54457 9.71669 7.28333 8.97796 7.28333 8.06669C7.28333 7.15542 6.54457 6.41669 5.63333 6.41669C4.72209 6.41669 3.98333 7.15542 3.98333 8.06669Z"
    />
  </svg>
);

export default SvgIDragHandle;
