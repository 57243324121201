export const introCopy = [
  '"Mr. Wolf", actually.',
  "Comes from the movie.",
  "Pulp Fiction…",
  "My favorite!",
  "Like the character",
  "they say I solve problems.",
  "Who are they?",
  "Well...",
  "You're starting to ask too much.",
  "...",
  "👋 Hi there!",
  "I'm Bruno",
  "I’m a coder + designer.",
];
